'use strict'

###*
 # @ngdoc object
 # @name mundoReporting.service:ReportFieldService

 # @description

###
class ReportFieldService
  ### @ngInject ###
  constructor:(
    $log
    $filter
    $q
    $window
    $timeout
    ReportConfigurationManager
    ReportConfigurationService
    UserPermissions
    UiHelpers
    $translate
    _
    uuid4
    Restangular
    MundoMap
  ) ->

    @getSourceFields = (source) ->
      fields = (_.values source.fields)
        .filter (v) -> v.options? and v.options.field
        .sort (a, b) -> a.weight - b.weight

      return fields

    @getFieldsData = (source, configuration) ->
      fields = source.fields

      # Go and filter out the selected fields from all the possible fields
      selected = _.filter fields, (sourceField) ->
        result = _.findIndex configuration.fields, (cfgField) ->
          return sourceField.field == cfgField.field
        return result != -1

      # Set the _selected parameter on all source fields. It's the model
      angular.forEach fields, (f) ->
        f._selected = false

        angular.forEach selected, (s) ->
          if s.code == f.code
            f._selected = true

      state = {progress: false}

      data =
        fields: fields
        selected: selected
        state: state
        toggleField: (field) ->
          # Always assume inverse values for
          # field._selected, since ng-model
          # is triggered BEFORE ng-change
          if not field._selected
            for v, k in selected
              if v? and v.code == field.code
                selected.splice k, 1
          else
            selected.push field

          # If this field is being selected and it is
          # not compatible with other fields, deselect
          # other incompatible fields
          if field._selected
            if field.options? and field.options.exclude?
              regex = new RegExp(field.options.exclude)
              for v, k in angular.copy selected
                if v? and v.code? and v.code.match regex
                  for w, l in selected when (w? and w.code? and (w.code == v.code))
                    selected.splice l, 1
                    w._selected = false
                  for w, l in fields when (w? and w.code? and (w.code == v.code))
                    w._selected = false
        canSetAsDefault: () ->
          return UserPermissions.check 'manage all MundoMosaSettingsBundle:Setting entities'
        setAsDefault: () =>
          state.progress = true
          @setDefaultFields selected
          .then () ->
            state.progress = false
          , () ->
            state.progress = false


      data

    @selectFields = (source, configuration) =>

      data = @getFieldsData source, configuration

      fields.forEach (f) ->
        f._selected = false

        selected.forEach (s) ->
          if s.code == f.code
            f._selected = true

      UiHelpers.openDialog
        template: 'mundo-reporting/views/dialogs/report-field-selection-dialog.tpl.html'
        title: 'app.report.columns'
        onConfirm: (result) =>
          @setFields selected
          @saveConfiguration
            fields: true
          .then () =>
            @refreshVisualizedData()
        data: data

angular
  .module('mundoReporting')
  .service 'ReportFieldService', ReportFieldService
